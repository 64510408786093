import React from "react";
import styles from "styles/main-activity/Icon.module.css";

const CaretDown = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles.icon_fg_caretDown}
        d="M16.9132 8.17892L10.6632 14.4289C10.5762 14.5163 10.4727 14.5857 10.3587 14.633C10.2447 14.6803 10.1226 14.7047 9.99918 14.7047C9.8758 14.7047 9.75362 14.6803 9.63967 14.633C9.52571 14.5857 9.42222 14.5163 9.33512 14.4289L3.08512 8.17892C2.909 8.0028 2.81006 7.76393 2.81006 7.51486C2.81006 7.26579 2.909 7.02692 3.08512 6.8508C3.26124 6.67468 3.50011 6.57573 3.74918 6.57573C3.99826 6.57573 4.23713 6.67468 4.41325 6.8508L9.99997 12.4375L15.5867 6.85002C15.7628 6.67389 16.0017 6.57495 16.2507 6.57495C16.4998 6.57495 16.7387 6.67389 16.9148 6.85002C17.0909 7.02614 17.1899 7.26501 17.1899 7.51408C17.1899 7.76315 17.0909 8.00202 16.9148 8.17814L16.9132 8.17892Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CaretDown;
