import React from "react";
import styles from "styles/main-activity/Icon.module.css";

const CaretDownFill = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        className={styles.icon_fg_caretDown}
        d="M13.4624 6.30625C13.4241 6.21525 13.3598 6.13761 13.2775 6.08311C13.1952 6.02861 13.0986 5.99969 12.9999 6H2.99985C2.90114 5.99969 2.80455 6.02861 2.72224 6.08311C2.63994 6.13761 2.5756 6.21525 2.53735 6.30625C2.50133 6.39861 2.49219 6.49927 2.511 6.59661C2.5298 6.69394 2.57577 6.78396 2.6436 6.85625L7.6436 11.8563C7.73917 11.9487 7.86691 12.0003 7.99985 12.0003C8.13279 12.0003 8.26053 11.9487 8.3561 11.8563L13.3561 6.85625C13.4239 6.78396 13.4699 6.69394 13.4887 6.59661C13.5075 6.49927 13.4984 6.39861 13.4624 6.30625Z"
        fill="white"
      />
    </svg>
  );
};

export default CaretDownFill;
